import { observable, flow, action, computed } from 'mobx';
import * as api from "../utility/dm.api";
import { SetAuthToken, UploadMediaTypes, decryptToken, initPaginateListResponseGlobal, isGUID } from '../utility/Common';
import { history } from "../history"
import { profile } from 'console';

class AccountStore {

  @observable errorMessage: string | null = null;

  @observable loading: {[key: string]: DataLoading} = {
    user: {...initLoading},
    role: {...initLoading},
    right: {...initLoading},
    currentUser: {...initLoading},
    rolesrights: {...initLoading}
  };
  @observable saveDataResponse: APIResponse<boolean> | null = null;
  @observable deleteDataResponse: APIResponse<boolean> | null = null;

  @action.bound
  clearDeleteDataResponse() {
    this.deleteDataResponse = null;
  }

  @observable authLoading: boolean = false;
  @observable loginResponse: LoginResponse | null = null;

  Login = flow(function*(
    this: AccountStore,
    payload: LoginPayload
  ) {
    this.authLoading = true;
    this.errorMessage = null;
    this.loginResponse = null;
    try {
      const response: APIResponse<LoginResponse> = yield api.Login(payload);
      this.loginResponse = response.data;
      SetAuthToken(this.loginResponse?.accessToken as string);
      this.currentUser = null;
      this.getCurrentUser();

      this.userSubscriptions = [];
      this.getUserSubscriptions();
     

      history.push('/');
    } catch (error) {
      this.errorMessage = "Unable to login."
    } finally {
      this.authLoading = false;
    }
  }).bind(this);

  @observable currentUser: AppUser | null = null;
  @observable currentUserRights: AppRight[] = [];
  getCurrentUser = flow(function* (this: AccountStore) {
    this.currentUser = null;
    this.currentUserRights = [];
    this.loading = {...this.loading, currentUser : {...this.loading.currentUser, itemLoading: true}};
    this.errorMessage = null;
    try {
      const decryptedToken = decryptToken();
      if(decryptedToken) {
        const currentUserResponse: APIResponse<AppUser> = yield api.getUser(decryptedToken.userID);
        this.currentUser = currentUserResponse.data;
      this.getRights(currentUserResponse.data.roleId);
      }
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {...this.loading, currentUser : {...this.loading.currentUser, itemLoading: false}};
    }
  }).bind(this);

  @observable userSubscriptions: any[] = [];
  getUserSubscriptions = flow(function* (this: AccountStore) {
    this.errorMessage = null;
    this.userSubscriptions = [];
    try {
      const userSubs: APIResponse<any[]> = yield api.getUserSubscriptions();
      this.userSubscriptions = userSubs.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    }
  }).bind(this);


  @observable roleRights: AppRight[] = [];
  getRights = flow(function* (this: AccountStore, roleID: string) {
    this.loading = {...this.loading, right : {...this.loading.right, listLoading: true}};
    this.roleRights= [];
    this.errorMessage = null;
    try {
      const response: APIResponse<AppRight[]> = yield api.getRights(roleID);
      this.roleRights = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {...this.loading, right : {...this.loading.right, listLoading: false}};
    }
  }).bind(this);

  assignRights = flow(function* (this: AccountStore, payload: AssignRights) {
    this.loading = {...this.loading, right : {...this.loading.right, saveDataLoading: true}};
    this.errorMessage = null;
    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.assignRights(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {...this.loading, right : {...this.loading.right, saveDataLoading: false}};
    }
  }).bind(this);

  @observable usersListData: PaginatedListResponse<AppUser[]> = { ...initPaginateListResponseGlobal<AppUser[]>([]), users: [] };
  getUsers = flow(function* (this: AccountStore, payload: PaginatedFiltersListPayload) {
    this.loading = {...this.loading, user : {...this.loading.user, listLoading: true}};
    this.errorMessage = null;
    this.usersListData = { ...initPaginateListResponseGlobal<AppUser[]>([]), users: [] };
    try {
      const response: APIResponse<PaginatedListResponse<AppUser[]>> = yield api.getUsers(payload);
      this.usersListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {...this.loading, user : {...this.loading.user, listLoading: false}};
    }
  }).bind(this);
  @computed
  get usersListDataComputed() {
    if(!this.currentUser)
      return { ...initPaginateListResponseGlobal<AppUser[]>([]), users: [] };
    const isCustomer: boolean = this.currentUser.customerId && this.currentUser.customerId !== '';
    const newListData: PaginatedListResponse<AppUser[]> = {...this.usersListData};
    newListData.users = [...this.usersListData.users.filter((x:AppUser) =>  isCustomer ? x.customerId === this.currentUser.customerId : true)] || [];
    return newListData;
  }

  @observable userData: AppUser | null = null;
  getUser = flow(function* (this: AccountStore, ID: string) {
    this.loading = {...this.loading, user : {...this.loading.user, itemLoading: true}};
    this.errorMessage = null;
    this.saveDataResponse = null;
    this.userData = null;
    try {
      const response: APIResponse<AppUser> = yield api.getUser(ID);
      this.userData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {...this.loading, user : {...this.loading.user, itemLoading: false}};
    }
  }).bind(this);

  saveUser = flow(function* (this: AccountStore, payload:  AppUser) {
    this.loading = {...this.loading, user : {...this.loading.user, saveDataLoading: true}};
    this.errorMessage = null;
    this.saveDataResponse = null;
    try {
      let imageUrl = null;
      if (payload.profileImage.name) {
        const formData = new FormData();
        const file: any = payload.profileImage;
        formData.append(
          "mediaFile",
          file,
          file.name
        );
        imageUrl = yield file ? api.uploadMedia(formData, UploadMediaTypes.Image) : null;
      }
      const profileImageData = imageUrl ? {
        url: imageUrl?.data,
        thumbnailUrl: imageUrl?.data,
        imageThumbnailMediaId: imageUrl.data,
        imageMediaId: imageUrl.data
      } : payload.profileImage
      this.saveDataResponse = yield api.saveUser({ ...payload, profileImage: profileImageData });
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
    this.loading = {...this.loading, user : {...this.loading.user, saveDataLoading: false}};
    }
  }).bind(this);

  deleteUser = flow(function* (this: AccountStore, ID: string) {
    this.loading = {...this.loading, user : {...this.loading.user, deleteLoading: true}};

    this.errorMessage = null;
    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteUser(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
    this.loading = {...this.loading, user : {...this.loading.user, deleteLoading: false}};
    }
  }).bind(this);

  @observable
  changePasswordResponse: boolean | null =  null;
  @observable
  changePasswordLoading: boolean = false;
  changePassword = flow(function* (this: AccountStore, payload: ChangePasswordPayload) {
    this.changePasswordLoading =  true;
    this.changePasswordResponse =  null;
    this.errorMessage = null;
    try {
      const response: APIResponse<boolean> = yield api.changePassword(payload);
      this.changePasswordResponse = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.changePasswordLoading =  false;
    }
  }).bind(this);


  @observable
  TpmNotifications: TpmNotification[] | null =  null;
  getTpmNotifications = flow(function* (this: AccountStore) {
    this.TpmNotifications =  null;
    try {
      const response: APIResponse<TpmNotification[]> = yield api.getNotifications();
      this.TpmNotifications = response.data;
    } catch (error) {
    }
  }).bind(this);


  @observable
  TpmUserNotifications: TpmUserNotification[] | null =  null;
  getTpmUserNotifications = flow(function* (this: AccountStore) {
    this.TpmNotifications =  null;
    try {
      const response: APIResponse<TpmUserNotification[]> = yield api.getUserNotifications();
      this.TpmUserNotifications = response.data;
    } catch (error) {
    }
  }).bind(this);

  @observable
  updateUserNotificationsResponse: boolean | null | 'loading' =  null;
  updateUserNotifications = flow(function* (this: AccountStore, payload: string[]) {
    this.updateUserNotificationsResponse =  'loading';
    try {
      const response: APIResponse<boolean> = yield api.updateUserNotifications(payload);
      this.updateUserNotificationsResponse = response.data;
    } catch (error) {
    }
  }).bind(this);


  @observable RolesRightsList: PaginatedListResponse<RolesRights[]> = { ...initPaginateListResponseGlobal<RolesRights[]>([]) };
  getRolesRights = flow(function* (this: AccountStore, payload: PaginatedListPayload) {

    this.loading = {...this.loading, rolesrights : {...this.loading.rolesrights, listLoading: true}};
    this.RolesRightsList = { ...initPaginateListResponseGlobal<RolesRights[]>([]) };

    try {
      const response: APIResponse<PaginatedListResponse<RolesRights[]>> = yield api.GetRolesRights(payload);
      this.RolesRightsList = response.data;
    } catch (error) {

    } finally {
      this.loading = {...this.loading, rolesrights : {...this.loading.rolesrights, listLoading: false}};
    }
  }).bind(this);

  @observable RolesRightItem: RolesRights | null = null;
  getRolesRight = flow(function* (this: AccountStore, ID: string) {

    this.loading = {...this.loading, rolesrights : {...this.loading.rolesrights, itemLoading: true}};
    this.RolesRightItem = null;

    try {
      const response: APIResponse<RolesRights> = yield api.GetRolesRight(ID);
      this.RolesRightItem = response.data;
    } catch (error) {
      
    } finally {
      this.loading = {...this.loading, rolesrights : {...this.loading.rolesrights, itemLoading: false}};
    }
  }).bind(this);

  AddRolesRights = flow(function* (this: AccountStore, payload:  RolesRights) {
    
    this.loading = {...this.loading, rolesrights : {...this.loading.rolesrights, saveDataLoading: true}};
    this.saveDataResponse = null;

    try {
      this.saveDataResponse = yield api.AddRolesRights(payload);
    } catch (error) {
      
    } finally {
      this.loading = {...this.loading, rolesrights : {...this.loading.rolesrights, saveDataLoading: false}};
    }
  }).bind(this);

  UpdateRolesRights = flow(function* (this: AccountStore, payload:  RolesRights) {
    
    this.loading = {...this.loading, rolesrights : {...this.loading.rolesrights, saveDataLoading: true}};
    this.saveDataResponse = null;

    try {
      this.saveDataResponse = yield api.UpdateRolesRights(payload);
    } catch (error) {
      
    } finally {
      this.loading = {...this.loading, rolesrights : {...this.loading.rolesrights, saveDataLoading: false}};
    }
  }).bind(this);

  DeleteRolesRights = flow(function* (this: AccountStore, ID: string) {

    this.loading = {...this.loading, rolesrights : {...this.loading.rolesrights, deleteLoading: true}};
    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.DeleteRolesRights(ID);
    } catch (error) {
      
    } finally {
    this.loading = {...this.loading, rolesrights : {...this.loading.rolesrights, deleteLoading: false}};
    }
  }).bind(this);

  @observable EmailVerificationLoading: boolean = false;
  SendEmailVerification = flow(function* (this: AccountStore) {

    this.EmailVerificationLoading = true;
    try {
      yield api.SendEmailVerification();
    } catch (error) {
      
    } finally {
      this.EmailVerificationLoading = false;
    }
  }).bind(this);

  VerifyEmail = flow(function* (this: AccountStore, Secret: string) {

    this.EmailVerificationLoading = true;
    try {
      yield api.VerifyEmail(Secret);
    } catch (error) {
      
    } finally {
      this.EmailVerificationLoading = false;
    }
  }).bind(this);

  @observable usersForFiltersData: PaginatedListResponse<AppUser[]> = { ...initPaginateListResponseGlobal<AppUser[]>([]), users: [] };
  getUsersForFilters = flow(function* (this: AccountStore, payload: UsersForFiltersPayload) {
    this.loading = {...this.loading, user : {...this.loading.user, listLoading: true}};
    this.errorMessage = null;
    this.usersForFiltersData = { ...initPaginateListResponseGlobal<AppUser[]>([]), users: [] };
    try {
      const response: APIResponse<PaginatedListResponse<AppUser[]>> = yield api.getUsers(payload);
      this.usersForFiltersData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {...this.loading, user : {...this.loading.user, listLoading: false}};
    }
  }).bind(this);
}

export default AccountStore;


const initLoading: DataLoading = {
  listLoading: false,
  saveDataLoading: false,
  deleteLoading: false,
  itemLoading: false,
}
