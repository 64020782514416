import * as request from "superagent";
import { errorCallback, showSuccessNoti } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

export const Login = (
  payload: LoginPayload
): Promise<APIResponse<LoginResponse>> =>
  request
    .post(`${apiURL}/Register/adminlogin/`, payload)
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getCustomers = (
  payload: PaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<CustomerListResponse[]>>> =>
  request
    .post(`${apiURL}/Customer/get/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getCustomer = (
  ID: string
): Promise<APIResponse<SingleCustomerResponse>> =>
  request
    .get(`${apiURL}/Customer/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveCustomer = (
  payload: CustomerPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Customer/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteCustomer = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Customer/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getPackages = (
  payload: PaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<PackagesListResponse[]>>> =>
  request
    .post(`${apiURL}/Package/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getPackage = (ID: string): Promise<APIResponse<Package>> =>
  request
    .get(`${apiURL}/Package/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const savePackage = (
  payload: PackagePayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Package/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deletePackage = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Package/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getBranches = (
  payload: ConfigPaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<BranchListResponse[]>>> =>
  request
    .post(`${apiURL}/Branche/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getBranch = (ID: string): Promise<APIResponse<Branch>> =>
  request
    .get(`${apiURL}/Branche/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveBranch = (
  payload: BranchPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Branche/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteBranch = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Branche/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getSections = (
  payload: PaginatedFiltersListPayload
): Promise<APIResponse<PaginatedListResponse<SectionListResponse[]>>> =>
  request
    .post(`${apiURL}/Section/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getSection = (ID: string): Promise<APIResponse<Section>> =>
  request
    .get(`${apiURL}/Section/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveSection = (
  payload: SectionPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Section/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteSection = (ID: string, recursive: boolean): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Section/controllactivation/${ID}/${recursive}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getSubSections = (
  payload: PaginatedFiltersListPayload
): Promise<APIResponse<PaginatedListResponse<SubSectionListResponse[]>>> =>
  request
    .post(`${apiURL}/SubSection/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => {
      errorCallback(err.response)
    });

export const getSubSection = (ID: string): Promise<APIResponse<SubSection>> =>
  request
    .get(`${apiURL}/SubSection/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveSubSection = (
  payload: SubSectionPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/SubSection/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteSubSection = (ID: string, recursive: boolean): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/SubSection/controllactivation/${ID}/${recursive}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));


    export const exportSubSections = (): Promise<any> =>
  request
    .get(`${apiURL}/SubSection/export`)
    .responseType("blob")
    .set("Authorization", token())
    .then((r: { body: any }) => {
      const url = window.URL.createObjectURL(new Blob([r.body]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `TPM-SubSection-Dump.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => errorCallback(err.response));

export const getAssets = (
  payload: PaginatedFiltersListPayload
): Promise<APIResponse<PaginatedListResponse<AssetListResponse[]>>> =>
  request
    .post(`${apiURL}/Asset/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getAsset = (ID: string): Promise<APIResponse<Asset>> =>
  request
    .get(`${apiURL}/Asset/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exportAssets = (): Promise<any> =>
  request
    .get(`${apiURL}/Asset/export`)
    .responseType("blob")
    .set("Authorization", token())
    .then((r: { body: any }) => {
      const url = window.URL.createObjectURL(new Blob([r.body]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `TPM-Asset-Dump.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => errorCallback(err.response));

export const saveAsset = (
  payload: AssetPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Asset/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteAsset = (ID: string, recursive: boolean): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Asset/controllactivation/${ID}/${recursive}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getShifts = (
  payload: PaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<ShiftListResponse[]>>> =>
  request
    .post(`${apiURL}/Shift/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getShift = (ID: string): Promise<APIResponse<Shift>> =>
  request
    .get(`${apiURL}/Shift/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveShift = (
  payload: ShiftPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Shift/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteShift = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Shift/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const saveCustomerShifts = (
  payload: string[]
): Promise<APIResponse<boolean>> =>
  request
    .ost(`${apiURL}/Shift/savecustomershifts/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getEquipments = (
  payload: PaginatedFiltersListPayload
): Promise<APIResponse<PaginatedListResponse<EquipmentListResponse[]>>> =>
  request
    .post(`${apiURL}/Equipment/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exportEquipments = (): Promise<any> =>
  request
    .get(`${apiURL}/Equipment/export`)
    .responseType("blob")
    .set("Authorization", token())
    .then((r: { body: any }) => {
      const url = window.URL.createObjectURL(new Blob([r.body]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `TPM-Equipment-Dump.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => errorCallback(err.response));

export const getAlarmedEquipments = (
  payload: GetAlarmedEquipmentPayload
): Promise<
  APIResponse<PaginatedListResponse<AlarmedEquipmentListResponse[]>>
> =>
  request
    .post(`${apiURL}/Equipment/getalarmed`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getAlarmedEquipmentsDetail = (
  payload: AlarmedEquipmentDetailPayload
): Promise<APIResponse<AlarmedEquipmentDetail[]>> =>
  request
    .post(`${apiURL}/Equipment/getalarmeddetail`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getEquipment = (ID: string): Promise<APIResponse<Equipment>> =>
  request
    .get(`${apiURL}/Equipment/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveEquipment = (
  payload: EquipmentPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Equipment/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteEquipment = (ID: string, recursive: boolean): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Equipment/controllactivation/${ID}/${recursive}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getInspectionPoints = (
  payload: PaginatedFiltersListPayload
): Promise<APIResponse<PaginatedListResponse<InspectionPointListResponse[]>>> =>
  request
    .post(`${apiURL}/Ip/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exportInspectionPoints = (): Promise<any> =>
  request
    .get(`${apiURL}/Ip/export`)
    .responseType("blob")
    .set("Authorization", token())
    .then((r: { body: any }) => {
      const url = window.URL.createObjectURL(new Blob([r.body]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `TPM-IP-Dump.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => errorCallback(err.response));

export const getEquipmentInspectionPoints = (
  payload: PaginatedListPayload,
  equipmentID: string
): Promise<APIResponse<PaginatedListResponse<InspectionPointListResponse[]>>> =>
  request
    .post(`${apiURL}/Ip/getByEquipment/${equipmentID}`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getInspectionPoint = (
  ID: string
): Promise<APIResponse<InspectionPoint>> =>
  request
    .get(`${apiURL}/Ip/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveInspectionPoint = (
  payload: InspectionPoint
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Ip/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteInspectionPoint = (
  ID: string
): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Ip/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getIpAnomalyCount = (
      ID: string
    ): Promise<APIResponse<IpAnomalyCountResponse[]>> =>
      request
        .post(`${apiURL}/Ip/ipanamolycount/${ID}`)
        .set("Authorization", token())
        .then((r: { body: any }) => {
          return r.body;
        })
        .catch((err) => errorCallback(err.response));

export const getRoutes = (
  payload: PaginatedFiltersListPayload
): Promise<APIResponse<PaginatedListResponse<RouteListResponse[]>>> =>
  request
    .post(`${apiURL}/Route/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getRoute = (ID: string): Promise<APIResponse<Route>> =>
  request
    .get(`${apiURL}/Route/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveRoute = (payload: Route): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Route/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteRoute = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Route/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getRouteSchedules = (
  payload: PaginatedListPayloadRS
): Promise<APIResponse<PaginatedListResponse<RouteScheduleListData[]>>> =>
  request
    .post(`${apiURL}/Route/getrouteschedule`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getsinglerouteschedule = (
  payload: string
): Promise<APIResponse<RouteScheduleListData>> =>
  request
    .post(
      `${apiURL}/Route/getsinglerouteschedule?routeScheduleId=${payload}`,
      {}
    )
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const changeScheduledRouteAssignee = (
  payload: ChangeRouteAssignedToPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Route/ChangeScheduledRouteAssignee`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getWorkOrders = (
  payload: PaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<WorkOrderListResponse[]>>> =>
  request
    .post(`${apiURL}/WorkOrders/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getWorkOrdersWeb = (
  payload: PaginatedFiltersListPayload
): Promise<APIResponse<PaginatedListResponse<WorkOrderListResponse[]>>> =>
  request
    .post(`${apiURL}/WorkOrders/getweb`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getWorkOrder = (ID: string): Promise<APIResponse<WorkOrder>> =>
  request
    .get(`${apiURL}/WorkOrders/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveWorkOrder = (
  payload: WorkOrder
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/WorkOrders/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteWorkOrder = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/WorkOrders/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getCustomerSubscriptions = (
  payload: PaginatedListPayload
): Promise<
  APIResponse<PaginatedListResponse<CustomerSubscriptionListResponse[]>>
> =>
  request
    .post(`${apiURL}/CustomerSubscription/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getUserSubscriptions = (): Promise<APIResponse<any[]>> =>
  request
    .get(`${apiURL}/CustomerSubscription/getusersubscriptions`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getCustomerSubscription = (
  ID: string
): Promise<APIResponse<CustomerSubscription>> =>
  request
    .get(`${apiURL}/CustomerSubscription/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveCustomerSubscription = (
  payload: CustomerSubscription
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/CustomerSubscription/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteCustomerSubscription = (
  ID: string
): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/CustomerSubscription/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getTeam = (ID: string): Promise<APIResponse<TeamPayload>> =>
  request
    .get(`${apiURL}/Teams/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const deleteTeam = (
  ID: string
): Promise<APIResponse<TeamPayload>> =>
  request
    .get(`${apiURL}/Teams/controllactivation/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getTeams = (payload: PaginatedListPayload):
Promise<APIResponse<Team>> =>
      request
        .post(`${apiURL}/Teams/get`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => {
          errorCallback(err.response);
        });
    
export const saveTeams = (payload: TeamPayload): 
Promise<APIResponse<boolean>> =>
      request
        .post(`${apiURL}/Teams/save`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => {
          showSuccessNoti();
          return r.body;
        })
        .catch((err) => {
          errorCallback(err.response);
        });

        export const getDocuments = (
          payload: GetDocumentsPayload
        ): Promise<APIResponse<DocumentsListResponse>> =>
          request
            .post(`${apiURL}/Document/get`, payload)
            .set("Authorization", token())
            .then((r: { body: any }) => r.body)
            .catch((err) => {
              errorCallback(err.response);
            });

        export const saveDocuments = (
          payload: DocumentsPayload
        ): Promise<APIResponse<boolean>> =>
          request
            .post(`${apiURL}/Document/save`, payload)
            .set("Authorization", token())
            .then((r: { body: any }) => {
              showSuccessNoti();
              return r.body;
            })
            .catch((err) => {
              errorCallback(err.response);
            });

        export const deleteDocuments = (
          ID: string,
          recursiveDelete: boolean
        ): Promise<APIResponse<boolean>> =>
          request
            .get(
              `${apiURL}/Document/controllactivation/${ID}/${recursiveDelete}`
            )
            .set("Authorization", token())
            .then((r: { body: any }) => r.body)
            .catch((err) => errorCallback(err.response));

        export const getDocument = (
          ID: string
        ): Promise<APIResponse<DocumentsListResponse>> =>
          request
            .get(`${apiURL}/Document/getedit/${ID}/`)
            .set("Authorization", token())
            .then((r: { body: any }) => r.body)
            .catch((err) => errorCallback(err.response));
            
        export const getEquipmentsTypes = (
          payload: PaginatedListPayload
        ): Promise<APIResponse<EquipmentTypesResponse>> =>
          request
            .post(`${apiURL}/EquipmentTypes/get`, payload)
            .set("Authorization", token())
            .then((r: { body: any }) => r.body)
            .catch((err) => {
              errorCallback(err.response);
            });

/////////////////////// Accounts TABLES //////////////////////////////////////

export const getUsers = (
  payload: PaginatedFiltersListPayload
): Promise<APIResponse<PaginatedListResponse<AppUser[]>>> =>
  request
    .post(`${apiURL}/Register/getusers`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getUser = (ID: string): Promise<APIResponse<AppUser>> =>
  request
    .get(`${apiURL}/Register/getedituser/${ID}?userId=${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveUser = (payload: AppUser): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Register/saveuser/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteUser = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Register/controlluseractivation/${ID}/false/true`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const changeActiveUserStatus = (
  ID: string,
  isActive: boolean
): Promise<APIResponse<boolean>> =>
  request
    .get(
      `${apiURL}/Register/controllactivation/${ID}/${isActive.toString()}/false`
    )
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const runScheduler = (): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Route/routeschedulingbackgroundjob`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const changePassword = (
  payload: ChangePasswordPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Register/changepassword`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getNotifications = (): Promise<APIResponse<TpmNotification[]>> =>
  request
    .get(`${apiURL}/Register/notifications`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getUserNotifications = (): Promise<
  APIResponse<TpmUserNotification[]>
> =>
  request
    .get(`${apiURL}/Register/UserNotifications`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const updateUserNotifications = (
  payload: string[]
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Register/UpdateUserNotifications`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const uploadMedia = (file: FormData, filetypeId: string): Promise<APIResponse<string>> =>
  request
    .post(`${apiURL}/MediaHandler/uploadmedia?mediaType=${filetypeId}`, file)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getUsersForFilters = (
  payload: UsersForFiltersPayload
): Promise<APIResponse<PaginatedListResponse<AppUser[]>>> =>
  request
    .post(`${apiURL}/Register/getusers`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

    /////////////////////// Email Verification //////////////////////////////////////

    export const SendEmailVerification = (): Promise<APIResponse<boolean>> =>
      request
        .get(`${apiURL}/Register/SendEmailVerification`)
        .set("Authorization", token())
        .then((r: { body: any }) => {
          showSuccessNoti();
          return r.body;
        })
        .catch((err) => errorCallback(err.response));

        export const VerifyEmail = (
          secret: string,
        ): Promise<APIResponse<boolean>> =>
          request
            .post(`${apiURL}/Register/VerifyEmail/${secret}`, {})
            .set("Authorization", token())
            .then((r: { body: any }) => {
              showSuccessNoti();
              return r.body;
            })
            .catch((err) => errorCallback(err.response));
